import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSplide from '@splidejs/vue-splide';

const app = createApp(App)
app.directive('focus', {
    mounted(el) {
      el.focus()
    }
  })
app.use(store).use(router).use( VueSplide ).mount('#app')
