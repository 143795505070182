import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: ()=> import('@/pages/Main-page')
  },
  {
    path: '/repair',
    name: 'repair',
    component: ()=> import('@/pages/Repair-page')
  },
  {
    path: '/interior',
    name: 'interior',
    component: ()=> import('@/pages/Interior-page')
  },
  {
    path: '/furniture',
    name: 'furniture',
    component: ()=> import('@/pages/Furniture-page')
  },
  {
    path: '/product',
    name: 'product',
    component:()=> import('@/pages/Product-page') 
  },
  {
    path: '/admproduct',
    name: 'admproduct',
    component:()=> import('@/pages/AdminProduct-page') 
  },
  {
    path: '/texture',
    name: 'texture',
    component:()=> import('@/pages/Texture-page') 
  },
  {
    path: '/admin',
    name: 'admin',
    component:()=> import('@/components/admin/Admin-main') 
  },
  {
    path: '/contacts',
    name: 'contacts',
    component:()=> import('@/pages/Contacts') 
  },
  {
    path: '/confidential',
    name: 'confidential',
    component:()=> import('@/pages/Confidential') 
  },
  {
    path: '/catalog',
    name: 'catalog',
    component:()=> import('@/pages/Catalog-page') 
  },
  {
    path: '/cart',
    name: 'cart',
    component:()=> import('@/pages/Cart-page') 
  },
  {
    path: '/account',
    name: 'account',
    component:()=> import('@/components/account/pages/Account-main-page') 
  }, 
  {
    path: '/proposals',
    name: 'proposals',
    component:()=> import('@/components/account/pages/Proposal-page') 
  }, 
  {
    path: '/admin-orders',
    name: 'admin-orders',
    component:()=> import('@/components/admin/Orders-page') 
  },
  {
    path: '/success',
    name: 'success',
    component:()=> import('@/pages/Success-page') 
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
        return {el: to.hash, behavior: 'smooth'}
    } else {
        return { x: 0, y: 0 }
    }
},
})

export default router
